import React from "react";

export default function HotelView() {
  return (
    <div className="w-blocks-group-wrapper blocks-group-wrapper_2QF">
      <div
        id="blockId-5ca5ee7871b4680026b20d71"
        data-block-name="custom-block"
        data-block-categories="custom"
        data-test="block-wrapper"
        data-anchor="Discover"
        className="block-wrapper w-block-wrapper block-wrapper_3Um background_3lr background w-state-has-background"
      >
        <div style={{ display: "none" }} id="Discover" />
        <div
          className="w-block-background background-wrapper_RZ2"
          aria-hidden="true"
          data-background--hide="false"
          data-component="background"
          data-type="color"
          data-data-loaded="true"
        >
          <div
            className="background_2xT"
            style={{ background: "#fff" }}
            data-role="placeholder"
          />
        </div>
        <section className="w-section">
          <div className="w-section__inner">
            <header className="w-section__header">
              <div
                className="w-grid w-grid--row w-grid--gap"
                data-columns-count={1}
              >
                <div className="w-grid__inner">
                  <div className="w-grid__item w-grid__item--top">
                    <div className="w-grid__item-inner w-grid__item-panel w-text-center w-088754deb0dd8820b733e12d9894218e">
                      <h2
                        className="text_1k0 ui-headingLg w-heading-lg"
                        data-component="text"
                      >
                        <span
                          className="w-text-block"
                          style={{ display: "block" }}
                        >
                          <span className="w-text-content">
                            МЕСТО ГДЕ МОЖНО ХОРОШО ПРОВЕСТИ ВРЕМЯ
                          </span>
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="w-section__content">
              <div
                className="w-grid w-grid--row w-grid--gap"
                data-columns-count={1}
              >
                <div className="w-grid__inner">
                  <div className="w-grid__item w-grid__item--top">
                    <div className="w-grid__item-inner w-grid__item-panel w-text-left w-088754deb0dd8820b733e12d9894218e">
                      <div className="w-picture-wrapper media-wrapper_3VN">
                        <picture
                          className="w-picture ui-picture_2i5"
                          style={{ paddingTop: "38.21%" }}
                          data-component="picture"
                          data-images="[]"
                          data-fallbackurl="//res2.weblium.site/res/5d6521c1b173b6002449a088/5d6522b07a1f8a0024c2ddb7_optimized_1600_c1600x700-0x0"
                          data-was-processed="true"
                          data-loaded="true"
                        >
                          <img
                            alt="Illustration"
                            data-fallback-url="//res2.weblium.site/res/5d6521c1b173b6002449a088/5d6522b07a1f8a0024c2ddb7_optimized_1600_c1600x700-0x0"
                            className="w-picture__image ui-image_3aJ"
                            style={{
                              objectFit: "cover",
                              objectPosition: "50% 56.908%",
                              fontFamily: "object-fit: cover",
                            }}
                            src="//res2.weblium.site/res/5d6521c1b173b6002449a088/5d6522b07a1f8a0024c2ddb7_optimized_1600_c1600x700-0x0.webp"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
