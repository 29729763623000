import React from "react"
import "./MapComponents.css"
import { YMaps, Map, Placemark } from "react-yandex-maps"

export default function MapComponent() {
  const handleClick = () => {
    const yandexNavigatorUrl = `yandexnavi://build_route_on_map?lat_to=43.2492&lon_to=42.631482`
    console.log(yandexNavigatorUrl)
    window.location.href = yandexNavigatorUrl
  }
  return (
    <div className="map_container">
      <div className="map_holder">
        <YMaps>
          <Map
            height="100%"
            width="100%"
            defaultState={{ center: [43.2492, 42.631482], zoom: 16 }}
          >
            <Placemark geometry={[43.2492, 42.631482]} />
          </Map>
        </YMaps>
      </div>
      <div className="title-hotel">
        <div
          className="background-wrapper_RZ2"
          aria-hidden="true"
          data-background--hide="false"
          data-component="background"
          data-type="color"
          data-data-loaded="true"
        ></div>
        <h3
          className="heading__2W2Ox w-text-center w-heading--50 text_1k0 ui-headingLg w-heading-lg"
          data-component="text"
        >
          <span className="w-text-block" style={{ display: "block" }}>
            <span className="w-text-content">Elbrus Plaza</span>
          </span>
        </h3>
        <ul
          className="contacts-list__3QpvH"
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <li>
            <p
              className="contacts__desc__uA20n w-text-center text_1k0 ui-text w-body"
              data-component="text"
            >
              <span className="w-text-block" style={{ display: "block" }}>
                <span className="w-text-content">
                  <br />
                  Балкарская ул., 36А, село Тегенекли
                </span>
                <button onClick={handleClick}>
                  Открыть в Яндекс.Навигаторе
                </button>
              </span>
            </p>
          </li>
          <li>
            <p
              className="contacts__desc__uA20n w-text-center text_1k0 ui-text w-body"
              data-component="text"
            >
              <span style={{ display: "block" }} className="w-text-block ">
                <span className="w-text-content">
                  {/* <span style={{ fontWeight: "bold" }}>+7 (928) 076-79-07</span> */}
                  <a href="tel:+79280767907">+7 (928) 076-79-07</a>
                </span>
              </span>
              <span style={{ display: "block" }} className="w-text-block ">
                <span className="w-text-content">
                  {/* <span style={{ fontWeight: "bold" }}>+7 (926) 086-86-89</span> */}
                  <a href="tel:+79264121120">+7 (926) 412-11-20</a>
                </span>
              </span>
            </p>
          </li>
          <li>
            <p
              className="contacts__desc__uA20n w-text-center text_1k0 ui-text w-body"
              data-component="text"
              data-replace="true"
            >
              <span className="w-text-block" style={{ display: "block" }}>
                <span className="w-text-content">
                  <span style={{ fontWeight: "bold" }}>
                    elbrusplaza@gmail.com
                  </span>
                </span>
              </span>
            </p>
          </li>
          <ul
            className="zoom-in-center_1T- normal_2Pr"
            style={{
              marginBottom: "-15px",
              marginRight: "-7.5px",
              marginLeft: "-7.5px",
              fontSize: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "grey",
              gap: "10px",
              padding: "10px",
              width: "200px",
              margin: 0,
            }}
            data-animation="zoom-in-center_1T- normal_2Pr"
          >
            <li className="social-icons_3iz">
              <a
                className="facebook-5c4731926265a30025fe57eb-socials social-icons_icon_3o6 undefined"
                rel="noopener"
                href="https://t.me/elbrusplazahotel"
                style={{
                  width: "30px",
                  height: "30px",
                  color: "#e6e6e6",
                  opacity: 1,
                }}
              >
                <svg
                  fill="#fff"
                  // height="800px"
                  // width="800px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 485 485"
                  // xml:space="preserve"
                  className="iconTelegram"
                >
                  <g>
                    <path
                      className="iconTelegram__icon"
                      d="M90.564,236.876l72.583,27.089l28.094,90.351c1.798,5.784,8.874,7.923,13.569,4.083l40.46-32.981
		c4.24-3.458,10.282-3.628,14.712-0.412l72.975,52.981c5.023,3.65,12.142,0.899,13.401-5.172l53.458-257.136
		c1.375-6.632-5.139-12.164-11.454-9.721L90.48,220.873C83.128,223.706,83.191,234.114,90.564,236.876z M186.713,249.546
		l141.854-87.367c2.548-1.565,5.172,1.882,2.981,3.913L214.479,274.913c-4.116,3.829-6.77,8.957-7.522,14.522l-3.986,29.553
		c-0.531,3.945-6.071,4.335-7.162,0.517l-15.337-53.891C178.717,259.468,181.276,252.901,186.713,249.546z"
                    />
                    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
                  </g>
                </svg>
              </a>
            </li>
            <li className="social-icons_3iz">
              <a
                className="instagram-5c4731926265a30025fe57eb-socials social-icons_icon_3o6 undefined"
                rel="noopener"
                href="https://instagram.com/elbrusplaza"
                style={{
                  width: "30px",
                  height: "30px",
                  color: "#e6e6e6",
                  opacity: 1,
                }}
              >
                <svg className="noFill_1L9 svgIcon_2Cq" viewBox="0 0 32 32">
                  <g
                    id="Artboard-Copy-60"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path
                      d="M0,0 L32,0 L32,32 L0,32 L0,0 Z M12.7045454,8 C10.1221593,8 8,10.1193185 8,12.7045454 L8,19.2954545 C8,21.8778407 10.1193185,24 12.7045454,24 L19.2954545,24 C21.8778407,24 24,21.8806822 24,19.2954545 L24,12.7045454 C24,10.1221593 21.8806822,8 19.2954545,8 L12.7045454,8 Z M12.7045454,9.45454545 L19.2954545,9.45454545 C21.0937498,9.45454545 22.5454545,10.9062502 22.5454545,12.7045454 L22.5454545,19.2954545 C22.5454545,21.0937498 21.0937498,22.5454545 19.2954545,22.5454545 L12.7045454,22.5454545 C10.9062502,22.5454545 9.45454545,21.0937498 9.45454545,19.2954545 L9.45454545,12.7045454 C9.45454545,10.9062502 10.9062502,9.45454545 12.7045454,9.45454545 Z M20.2954545,11.0454545 C19.9289774,11.0454545 19.6363636,11.3380684 19.6363636,11.7045454 C19.6363636,12.0710225 19.9289774,12.3636364 20.2954545,12.3636364 C20.6619316,12.3636364 20.9545454,12.0710225 20.9545454,11.7045454 C20.9545454,11.3380684 20.6619316,11.0454545 20.2954545,11.0454545 Z M16,11.6363636 C13.599432,11.6363636 11.6363636,13.599432 11.6363636,16 C11.6363636,18.400568 13.599432,20.3636364 16,20.3636364 C18.400568,20.3636364 20.3636364,18.400568 20.3636364,16 C20.3636364,13.599432 18.400568,11.6363636 16,11.6363636 Z M16,13.0909091 C17.6164771,13.0909091 18.9090909,14.3835229 18.9090909,16 C18.9090909,17.6164771 17.6164771,18.9090909 16,18.9090909 C14.3835229,18.9090909 13.0909091,17.6164771 13.0909091,16 C13.0909091,14.3835229 14.3835229,13.0909091 16,13.0909091 Z"
                      className="outline_28N"
                      fillRule="nonzero"
                    />
                    <path
                      d="M12.7045454,8 C10.1221593,8 8,10.1193185 8,12.7045454 L8,19.2954545 C8,21.8778407 10.1193185,24 12.7045454,24 L19.2954545,24 C21.8778407,24 24,21.8806822 24,19.2954545 L24,12.7045454 C24,10.1221593 21.8806822,8 19.2954545,8 L12.7045454,8 Z M12.7045454,9.45454545 L19.2954545,9.45454545 C21.0937498,9.45454545 22.5454545,10.9062502 22.5454545,12.7045454 L22.5454545,19.2954545 C22.5454545,21.0937498 21.0937498,22.5454545 19.2954545,22.5454545 L12.7045454,22.5454545 C10.9062502,22.5454545 9.45454545,21.0937498 9.45454545,19.2954545 L9.45454545,12.7045454 C9.45454545,10.9062502 10.9062502,9.45454545 12.7045454,9.45454545 Z M20.2954545,11.0454545 C19.9289774,11.0454545 19.6363636,11.3380684 19.6363636,11.7045454 C19.6363636,12.0710225 19.9289774,12.3636364 20.2954545,12.3636364 C20.6619316,12.3636364 20.9545454,12.0710225 20.9545454,11.7045454 C20.9545454,11.3380684 20.6619316,11.0454545 20.2954545,11.0454545 Z M16,11.6363636 C13.599432,11.6363636 11.6363636,13.599432 11.6363636,16 C11.6363636,18.400568 13.599432,20.3636364 16,20.3636364 C18.400568,20.3636364 20.3636364,18.400568 20.3636364,16 C20.3636364,13.599432 18.400568,11.6363636 16,11.6363636 Z M16,13.0909091 C17.6164771,13.0909091 18.9090909,14.3835229 18.9090909,16 C18.9090909,17.6164771 17.6164771,18.9090909 16,18.9090909 C14.3835229,18.9090909 13.0909091,17.6164771 13.0909091,16 C13.0909091,14.3835229 14.3835229,13.0909091 16,13.0909091 Z"
                      className="logo_2cS"
                      fillRule="nonzero"
                    />
                    <path
                      d="M1,1 L1,31 L31,31 L31,1 L1,1 Z M0,0 L32,0 L32,32 L0,32 L0,0 Z"
                      className="border_3Tk"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </a>
            </li>
            <li
              className="social-icons_3iz"
              // style={{
              //   marginRight: "7.5px",
              //   marginLeft: "7.5px",
              //   marginBottom: "15px",
              // }}
            >
              <a
                className="vk-5c4731926265a30025fe57eb-socials social-icons_icon_3o6 undefined"
                rel="noopener"
                href="https://vk.com/elbrusplazatop"
                style={{
                  width: "30px",
                  height: "30px",
                  color: "#e6e6e6",
                  opacity: 1,
                }}
              >
                <svg className="noFill_1L9 svgIcon_2Cq" viewBox="0 0 32 32">
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <path
                      d="M0,0 L32,0 L32,32 L0,32 L0,0 Z M24.5870118,11.7448158 C24.7120566,11.3155263 24.5870118,11 23.9914666,11 L22.0222569,11 C21.521515,11 21.2906955,11.2725395 21.16551,11.5730132 C21.16551,11.5730132 20.1641668,14.0847763 18.7454919,15.71625 C18.2865254,16.1885263 18.0779298,16.3387632 17.8275589,16.3387632 C17.7023734,16.3387632 17.5212061,16.1885263 17.5212061,15.7592368 L17.5212061,11.7448158 C17.5212061,11.2295526 17.3759066,11 16.9585748,11 L13.8641024,11 C13.5511387,11 13.3629385,11.2391053 13.3629385,11.4657632 C13.3629385,11.9541053 14.072276,12.0668553 14.1454181,13.4406974 L14.1454181,16.4247368 C14.1454181,17.0789474 14.0306413,17.1976316 13.7802703,17.1976316 C13.1127082,17.1976316 11.4888135,14.6745789 10.5257293,11.7878026 C10.3369665,11.2266579 10.147641,11 9.64436731,11 L7.67515761,11 C7.11252627,11 7,11.2725395 7,11.5730132 C7,12.1096974 7.66756209,14.7716974 10.1083975,18.2924211 C11.735668,20.6966447 14.0283907,22 16.1146278,22 C17.3663418,22 17.5212061,21.7105263 17.5212061,21.2119079 L17.5212061,19.3947368 C17.5212061,18.8157895 17.6397807,18.7002895 18.0361544,18.7002895 C18.3281601,18.7002895 18.828902,18.8505263 19.997206,20.0097237 C21.3324708,21.3837105 21.5526003,22 22.3035725,22 L24.2727822,22 C24.8354136,22 25.1167292,21.7105263 24.9544101,21.13925 C24.7768999,20.5698553 24.1392979,19.7438421 23.2935224,18.7646974 C22.8345559,18.2064474 22.1460358,17.6055 21.9374402,17.3048816 C21.6452939,16.9184342 21.7288446,16.7466316 21.9374402,16.4031711 C21.9374402,16.4031711 24.3366409,12.9255789 24.5870118,11.7448158 Z"
                      className="outline_28N"
                      fillRule="nonzero"
                    />
                    <g className="logo_2cS" fillRule="nonzero">
                      <path d="M24.5870118,11.7448158 C24.7120566,11.3155263 24.5870118,11 23.9914666,11 L22.0222569,11 C21.521515,11 21.2906955,11.2725395 21.16551,11.5730132 C21.16551,11.5730132 20.1641668,14.0847763 18.7454919,15.71625 C18.2865254,16.1885263 18.0779298,16.3387632 17.8275589,16.3387632 C17.7023734,16.3387632 17.5212061,16.1885263 17.5212061,15.7592368 L17.5212061,11.7448158 C17.5212061,11.2295526 17.3759066,11 16.9585748,11 L13.8641024,11 C13.5511387,11 13.3629385,11.2391053 13.3629385,11.4657632 C13.3629385,11.9541053 14.072276,12.0668553 14.1454181,13.4406974 L14.1454181,16.4247368 C14.1454181,17.0789474 14.0306413,17.1976316 13.7802703,17.1976316 C13.1127082,17.1976316 11.4888135,14.6745789 10.5257293,11.7878026 C10.3369665,11.2266579 10.147641,11 9.64436731,11 L7.67515761,11 C7.11252627,11 7,11.2725395 7,11.5730132 C7,12.1096974 7.66756209,14.7716974 10.1083975,18.2924211 C11.735668,20.6966447 14.0283907,22 16.1146278,22 C17.3663418,22 17.5212061,21.7105263 17.5212061,21.2119079 L17.5212061,19.3947368 C17.5212061,18.8157895 17.6397807,18.7002895 18.0361544,18.7002895 C18.3281601,18.7002895 18.828902,18.8505263 19.997206,20.0097237 C21.3324708,21.3837105 21.5526003,22 22.3035725,22 L24.2727822,22 C24.8354136,22 25.1167292,21.7105263 24.9544101,21.13925 C24.7768999,20.5698553 24.1392979,19.7438421 23.2935224,18.7646974 C22.8345559,18.2064474 22.1460358,17.6055 21.9374402,17.3048816 C21.6452939,16.9184342 21.7288446,16.7466316 21.9374402,16.4031711 C21.9374402,16.4031711 24.3366409,12.9255789 24.5870118,11.7448158" />
                    </g>
                    <path
                      d="M1,1 L1,31 L31,31 L31,1 L1,1 Z M0,0 L32,0 L32,32 L0,32 L0,0 Z"
                      className="border_3Tk"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  )
}
